import PropTypes from 'prop-types';
import styled from 'styled-components';

const Cover = ({ name, quote, handleSubmit }) => {
  return (
    <CoverContainer>
      <header>
        <h3>Värdenivå</h3>
        <h2>{Number(quote.answers.sumInsured).toLocaleString('sv-SE')} SEK</h2>
      </header>
      <main>
        <PriceContainer>
          <span>{Math.round(quote.premium.monthly)}</span> SEK/månad*
        </PriceContainer>
        <button type='button' name={name} onClick={handleSubmit}>
          Köp försäkring
        </button>
      </main>
    </CoverContainer>
  );
};

Cover.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  quote: PropTypes.shape({
    answers: PropTypes.shape({
      sumInsured: PropTypes.string.isRequired,
    }).isRequired,
    premium: PropTypes.shape({
      monthly: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default Cover;

const CoverContainer = styled.section`
  flex: 1;
  border: 0.125rem solid #08a9bc;

  header {
    background-color: #08a9bc;
    color: #fff;
    padding: 0.5rem;
  }

  main {
    display: flex;
    flex-direction: column;
    background-color: #d2edf1;
    padding: 0.5rem;
  }

  button {
    background-color: #08a9bc;
    font-family: Poppins;
    font-size: 1.2rem;
    font-weight: 400;
    color: #fff;
    border: none;
    padding: 1rem 1.5rem;
    cursor: pointer;

    &:hover {
      opacity: 0.85;
    }
  }
`;

const PriceContainer = styled.div`
  margin-bottom: 1rem;

  span {
    font-size: 4rem;
    font-weight: 700;
    padding-left: 1rem;
  }
`;
