import styled from 'styled-components';

const ContactInformation = () => {
  return (
    <div>
      <ContactInfo>
        Det gick inte att genomföra betalningen. Vänligen kontakta vår
        kundeservice på telefon <Phone>08-520 276 58</Phone> eller e-post{' '}
        <A href='info@linforsakring.se'>info@linforsakring.se</A> så hjälper vi
        dig.
      </ContactInfo>
    </div>
  );
};

export default ContactInformation;

const ContactInfo = styled.p`
  margin: 0.625rem;
  padding: 0.625rem;
`;

const A = styled.a`
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }
`;

const Phone = styled.span`
  white-space: nowrap;
  font-weight: 500;
`;
