import styled from 'styled-components';

import swedvaultLogo from '../images/swedvault-logo.png';
import linLogo from '../images/lin-logo.png';

const Header = ({ children }) => {
  return (
    <ViewHeader>
      <LogoContainer>
        <Logo src={swedvaultLogo} />
        <Logo src={linLogo} />
      </LogoContainer>
      {children}
    </ViewHeader>
  );
};

export default Header;

const ViewHeader = styled.header`
  margin: auto;
  margin-bottom: 3rem;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 4rem;
  margin-bottom: 2rem;
`;

const Logo = styled.img`
  width: auto;
  height: 4.5rem;
`;
