import styled from 'styled-components';

const Disclaimer = () => {
  return (
    <P>
      * Den premie som visas bygger på ditt pris för 1 års försäkring och delas
      sedan på 12 månader. Din faktiska månadspremie beräknas på exakt antal
      dagar i månaden.
    </P>
  );
};

export default Disclaimer;

const P = styled.p`
  margin-bottom: 1rem;
`;
