import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { configureAmplify } from '@eir/aws-authorization';
import OnlineSalesView from './views/OnlineSales/OnlineSalesView';
import PolicyLetterView from './views/PolicyLetter/PolicyLetterView';
import ContactInformation from './components/ContactInformation';
import { PaymentView, ReattachView } from '@eir/payment';
import GlobalStyle from './styles/globalStyle';
import './styles/fonts.css';
import { QuoteProvider } from './context';
import swedvaultLogo from './images/swedvault-logo.png';
import linLogo from './images/lin-logo.png';

configureAmplify(
  {
    endpointName: 'SwedvaultAPI',
    endpointUrl: process.env.REACT_APP_AWS_ENDPOINT_URL,
  },
  true
);

const App = () => {
  return (
    <>
      <GlobalStyle />
      <Router>
        <Switch>
          <Route
            exact
            path='/'
            render={props => (
              <QuoteProvider>
                <OnlineSalesView />
              </QuoteProvider>
            )}
          />
          <Route exact path='/p/:policyId' component={PolicyLetterView} />
          <Route
            exact
            path='/pay/:paymentProvider/:quoteId'
            render={props => (
              <PaymentView
                apiName='SwedvaultAPI'
                contactInfo={<ContactInformation />}
                logos={[swedvaultLogo, linLogo]}
              />
            )}
          />
          <Route
            exact
            path='/reattach/:paymentProvider/:ledgerId'
            render={Props => (
              <ReattachView
                apiName='SwedvaultAPI'
                contactInfo={<ContactInformation />}
                logos={[swedvaultLogo, linLogo]}
              />
            )}
          />
        </Switch>
      </Router>
    </>
  );
};

export default App;
