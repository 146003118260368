import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { createQuoteService } from '@eir/services';
import { formatDate } from '@eir/utils';

import Cover from './Cover';
import CustomerInformation from './CustomerInformation';
import Disclaimer from './Disclaimer';
import { useQuote } from '../../../context';
import { useForm } from '../../../hooks';
import Loading from '../../../components/Loading';

const FormContainer = () => {
  const {
    loading,
    orderNumber,
    priceQuoteHighSumInsured,
    priceQuoteLowSumInsured,
    socialSecurity,
    setLoading,
  } = useQuote();

  const history = useHistory();
  const scrollToElement = useRef(null);

  useEffect(() => {
    scrollToElement.current.scrollIntoView(true);
  }, []);

  const { handleSubmit, handleChange, data, errors } = useForm({
    initialValues: {
      coverLevel: 'Värdefacksförsäkring',
      email: '',
      excessLevel: '2000',
      firstName: '',
      lastName: '',
      orderNumber: orderNumber,
      orderNumberDescription: 'order number desc',
      paymentMethod: 'Fixed 1',
      phoneNumber: '',
      postCode: '',
      product: 'swedvault-safe-deposit',
      productVersion: 'latest',
      reference: 'osa',
      socialSecurity: socialSecurity.replace('-', ''),
      startDate: new Date(),
    },
    validations: {
      firstName: {
        required: {
          value: true,
          message: 'Fyll i ditt förnamn',
        },
      },
      lastName: {
        required: {
          value: true,
          message: 'Fyll i ditt efternamn',
        },
      },
      email: {
        required: {
          value: true,
          message: 'Fyll i din e-postadress',
        },
        pattern: {
          value: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
          message: 'Ange en e-postadress',
        },
      },
      phoneNumber: {
        required: {
          value: true,
          message: 'Fyll i ditt telefonnummer',
        },
        pattern: {
          value: '(07)[0-9]{8}$',
          message: 'Ange telefonnummer i formatet 07nnnnnnnn',
        },
      },
      postCode: {
        required: {
          value: true,
          message: 'Fyll i ditt postnummer',
        },
        pattern: {
          value: '[0-9]{5}$',
          message: 'Fyll i ditt postnummer',
        },
      },
    },
    onSubmit: e => {
      const body = { ...data };

      body.startDate = formatDate(body.startDate);
      body.socialSecurity = body.socialSecurity.replace('-', '');

      if (e.target.name === 'high') body.sumInsured = '200000';
      if (e.target.name === 'low') body.sumInsured = '100000';

      const getPurchaseQuote = async () => {
        setLoading({
          isLoading: true,
          text: 'Vänligen vänta, vi förbereder din betalning',
        });

        const quote = await createQuoteService('SwedvaultAPI', body);

        setLoading({ isLoading: true, text: '' });
        history.push(`/pay/trustly/${quote.id}`);
      };

      getPurchaseQuote();
    },
  });

  return loading.isLoading ? (
    <Loading message={loading.text} />
  ) : (
    <main ref={scrollToElement}>
      <CustomerInformation
        data={data}
        errors={errors}
        handleChange={handleChange}
      />
      <CoverList>
        <Cover
          name='low'
          quote={priceQuoteLowSumInsured}
          handleSubmit={handleSubmit}
        />
        <Cover
          name='high'
          quote={priceQuoteHighSumInsured}
          handleSubmit={handleSubmit}
        />
      </CoverList>
      <Disclaimer />
    </main>
  );
};

export default FormContainer;

const CoverList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 56.25rem) {
    flex-direction: row;
  }
`;
