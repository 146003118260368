import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const Loading = ({ message }) => {
  return (
    <LoadingContainer>
      <ChaseSpinner>
        <ChaseDot />
        <ChaseDot />
        <ChaseDot />
        <ChaseDot />
        <ChaseDot />
        <ChaseDot />
      </ChaseSpinner>
      <WaitText>{message ?? 'Please wait, fething data...'}</WaitText>
    </LoadingContainer>
  );
};

Loading.propTypes = {
  message: PropTypes.string,
};

export default Loading;

const LoadingContainer = styled.div`
  display: flex;
  flex-flow: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

const chase = keyframes`
  100% { transform: rotate(360deg) };
`;

const chaseDot = keyframes`
  80%, 100% { transform: rotate(360deg) };
`;

const chaseDotBefore = keyframes`
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  } 
`;

const ChaseSpinner = styled.div`
  width: 3rem;
  height: 3rem;
  position: relative;
  animation: ${chase} 2.5s infinite linear both;
`;

const ChaseDot = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${chaseDot} 2s infinite ease-in-out both;

  &:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #08a9bc;
    border-radius: 100%;
    animation: ${chaseDotBefore} 2s infinite ease-in-out both;
  }

  &:nth-child(1) {
    animation-delay: -1.1s;

    &:before {
      animation-delay: -1.1s;
    }
  }

  &:nth-child(2) {
    animation-delay: -1s;

    &:before {
      animation-delay: -1s;
    }
  }

  &:nth-child(3) {
    animation-delay: -0.9s;

    &:before {
      animation-delay: -0.9s;
    }
  }

  &:nth-child(4) {
    animation-delay: -0.8s;

    &:before {
      animation-delay: -0.8s;
    }
  }

  &:nth-child(5) {
    animation-delay: -0.7s;

    &:before {
      animation-delay: -0.7s;
    }
  }

  &:nth-child(6) {
    animation-delay: -0.6s;

    &:before {
      animation-delay: -0.6s;
    }
  }
`;

const WaitText = styled.div`
  margin-top: 1rem;
`;
