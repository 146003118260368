import styled from 'styled-components';

import { formatDate } from '@eir/utils';

import SocialSecuritySeInput from './SocialSecuritySeInput';
import { useForm } from '../../../hooks';
import { useQuote } from '../../../context';

const RequestPrice = () => {
  const {
    getPriceQuoteHighSumInsured,
    getPriceQuoteLowSumInsured,
    setLoading,
    setOrderNumber,
    setPriceQuotesRequested,
    setSocialSecurity,
  } = useQuote();

  const { handleSubmit, handleChange, data, errors } = useForm({
    initialValues: {
      coverLevel: 'Värdefacksförsäkring',
      excessLevel: '2000',
      orderNumber: '',
      orderNumberDescription: 'order number desc',
      paymentMethod: 'Fixed 1',
      product: 'swedvault-safe-deposit',
      productVersion: 'latest',
      reference: 'osa-price request',
      socialSecurity: '',
      startDate: new Date(),
    },
    validations: {
      socialSecurity: {
        required: {
          value: true,
          message: 'Fyll i ditt personnummer',
        },
        custom: {
          isValid: value => value.replace('-', '').length === 10,
          message: 'Fyll i ditt personnummer',
        },
      },
      orderNumber: {
        required: {
          value: true,
          message: 'Fyll i värdefacktes objectsnummer',
        },
      },
    },
    onSubmit: async () => {
      setLoading({
        isLoading: true,
        text: 'Vänligen vänta medan vi beräknar ditt pris',
      });
      const body = { ...data };

      setOrderNumber(body.orderNumber);
      setSocialSecurity(body.socialSecurity);
      body.startDate = formatDate(body.startDate);
      body.socialSecurity = body.socialSecurity.replace('-', '');

      const bodyForLowQuote = { ...body, sumInsured: '100000' };
      const bodyForHighQuote = { ...body, sumInsured: '200000' };

      const highQuote = getPriceQuoteHighSumInsured(bodyForHighQuote);
      const lowQuote = getPriceQuoteLowSumInsured(bodyForLowQuote);

      await Promise.all([highQuote, lowQuote]);

      setPriceQuotesRequested();
      setLoading({
        isLoading: false,
        text: '',
      });
    },
  });

  return (
    <RequestPriceSection>
      <InputWrapper>
        <InputContainer>
          <label>Personnummer</label>
          <SocialSecuritySeInput
            name='socialSecurity'
            value={data.socialSecurity}
            onChange={handleChange('socialSecurity')}
            autofocus
          />
          <ValidationErrorText>{errors?.socialSecurity}</ValidationErrorText>
        </InputContainer>
        <InputContainer>
          <label>Värdefackets objektsnummer</label>
          <input
            type='text'
            value={data.orderNumber}
            onChange={handleChange('orderNumber')}
          />
          <ValidationErrorText>{errors?.orderNumber}</ValidationErrorText>
        </InputContainer>
      </InputWrapper>
      <ButtonContainer>
        <Button type='button' onClick={handleSubmit}>
          Se ditt pris
        </Button>
      </ButtonContainer>
    </RequestPriceSection>
  );
};

RequestPrice.propTypes = {};

export default RequestPrice;

const RequestPriceSection = styled.section`
  max-width: 40rem;
  border: 0.125rem solid #08a9bc;
  background-color: #d2edf1;
  padding: 0.5rem;
  margin: auto;
  margin-bottom: 2rem;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0.5rem;

  @media (min-width: 43.75rem) {
    flex-direction: row;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 43.75rem) {
    width: 50%;
  }

  label {
  }
`;

const ValidationErrorText = styled.span`
  font-size: 0.8125rem;
  color: #f2545b;
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const Button = styled.button`
  background-color: #08a9bc;
  font-family: Poppins;
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    opacity: 0.85;
  }
`;
