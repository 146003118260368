import { useEffect } from 'react';
import styled from 'styled-components';

import { useQuote } from '../../context';
import Header from '../../components/Header';
import Loading from '../../components/Loading';
import Documents from './components/Documents';
import FormContainer from './components/FormContainer';
import NoQuote from './components/NoQuote';
import RequestPrice from './components/RequestPrice';

const OnlineSalesView = () => {
  const {
    loading,
    priceQuoteError,
    priceQuoteHighSumInsured,
    priceQuoteLowSumInsured,
    priceQuotesRequested,
  } = useQuote();

  const onResize = () => {
    window.parent.postMessage(
      {
        type: 'resize',
        height: document.body.scrollHeight,
      },
      '*'
    );
  };

  useEffect(() => {
    onResize();
    new ResizeObserver(_ => onResize()).observe(document.body);
  }, []);

  return loading.isLoading ? (
    <Loading message={loading.text} />
  ) : (
    <PageWrapper>
      <Header>
        <H1>
          Teckna <span>lin Värdefacksförsäkring</span>
        </H1>
      </Header>
      {!priceQuotesRequested && <RequestPrice />}
      {priceQuoteError && <NoQuote />}
      {priceQuoteHighSumInsured && priceQuoteLowSumInsured && <FormContainer />}
      <Documents />
    </PageWrapper>
  );
};

export default OnlineSalesView;

const PageWrapper = styled.div`
  margin: auto;
  margin-top: 5rem;
  max-width: 72rem;
  padding: 0 3rem;
`;

const H1 = styled.h1`
  text-align: center;

  span {
    white-space: nowrap;
  }
`;
