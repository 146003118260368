import React, { createContext, useContext, useState } from 'react';

import { createQuoteService } from '@eir/services';

const QuoteContext = createContext();

const QuoteProvider = ({ children }) => {
  const initialState = {
    socialSecurity: null,
    orderNumber: null,
    priceQuotesRequested: null,
    priceHasBeenFetched: false,
    priceRequestHasErrors: false,
    priceQuoteError: false,
    priceQuoteLowSumInsured: null,
    priceQuoteHighSumInsured: null,
    loading: {
      isLoading: false,
      text: '',
    },
  };

  const [state, setState] = useState(initialState);

  const setSocialSecurity = socialSecurity =>
    setState(state => ({ ...state, socialSecurity }));

  const setOrderNumber = orderNumber =>
    setState(state => ({ ...state, orderNumber }));

  const getPriceQuoteLowSumInsured = async body => {
    try {
      const quote = await createQuoteService('SwedvaultAPI', body);
      setState(state => ({ ...state, priceQuoteLowSumInsured: quote }));
      return quote;
    } catch (err) {
      setState(state => ({ ...state, priceQuoteError: true }));
    }
  };

  const getPriceQuoteHighSumInsured = async body => {
    try {
      const quote = await createQuoteService('SwedvaultAPI', body);
      setState(state => ({ ...state, priceQuoteHighSumInsured: quote }));
      return quote;
    } catch (err) {
      setState(state => ({ ...state, priceQuoteError: true }));
    }
  };

  const setPriceQuotesRequested = () =>
    setState(state => ({ ...state, priceQuotesRequested: true }));

  const setLoading = loading => setState(state => ({ ...state, loading }));

  const resetContext = () => setState(initialState);

  return (
    <QuoteContext.Provider
      value={{
        loading: state.loading,
        orderNumber: state.orderNumber,
        priceQuoteError: state.priceQuoteError,
        priceQuoteHighSumInsured: state.priceQuoteHighSumInsured,
        priceQuoteLowSumInsured: state.priceQuoteLowSumInsured,
        priceQuotesRequested: state.priceQuotesRequested,
        socialSecurity: state.socialSecurity,
        getPriceQuoteHighSumInsured,
        getPriceQuoteLowSumInsured,
        resetContext,
        setLoading,
        setPriceQuotesRequested,
        setOrderNumber,
        setSocialSecurity,
      }}
    >
      {children}
    </QuoteContext.Provider>
  );
};

const useQuote = () => useContext(QuoteContext);

export { QuoteProvider, useQuote };
