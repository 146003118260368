import { useRouteMatch } from 'react-router-dom';

import { getOsaPolicyService, useApiRequest } from '@eir/services';
import {
  Header,
  PaymentSchedule,
  PolicyContent,
  PolicyHolder,
  PolicyTable,
  Styles as S,
  SV_SE,
} from '@eir/letter';

import swedvaultLogo from '../../images/swedvault-logo.png';
import linLogo from '../../images/lin-logo.png';

const PolicyLetterView = () => {
  const match = useRouteMatch('/p/:policyId');
  const { loading, data, error } = useApiRequest(
    getOsaPolicyService('SwedvaultAPI', match.params.policyId)
  );

  const logoArray = [swedvaultLogo, linLogo];

  const revisedAnswers = [];
  const finalAnswers = [];
  const tableHeadings = ['Moment', 'Självrisk', 'Maximal ersättning per skada'];
  const tableValues = [
    { label: 'Brand', deductible: '2 000 kr', maxCompensation: '200 000 kr' },
    { label: 'Vatten', deductible: '2 000 kr', maxCompensation: '200 000 kr' },
    {
      label: 'Inbrott i vårdefack',
      deductible: '2 000 kr',
      maxCompensation: '200 000 kr',
    },
  ];
  let values = [];
  let keys = [];
  let policyPeriod = '';

  if (data?.answers) {
    values = Object.values(data.answers);
    keys = Object.keys(data.answers);
    policyPeriod = `${data.start} - ${data.end}`;

    values.forEach((value, index) => {
      revisedAnswers.push({ key: keys[index], value: value });
      if (index === values.length - 1) {
        revisedAnswers.push({
          key: 'policyPremium',
          value: data.policyPremium.totalPremiumExcludingTax,
        });
        revisedAnswers.push({ key: 'policyPeriod', value: policyPeriod });
      }
    });
  }

  const addValues = () => {
    finalAnswers.push({
      ...revisedAnswers.find(answer => answer.key === 'policyPeriod'),
      dataType: 'string',
      label: 'Försäkringsperiod',
    });
    finalAnswers.push({
      ...revisedAnswers.find(answer => answer.key === 'orderNumber'),
      dataType: 'string',
      label: 'Försäkrat värdefack',
    });
    finalAnswers.push({
      ...revisedAnswers.find(answer => answer.key === 'endDate'),
      dataType: 'string',
      label: 'Huvudförfallodag',
    });
    finalAnswers.push({
      ...revisedAnswers.find(answer => answer.key === 'policyPremium'),
      dataType: 'currency',
      label: 'Årspremie',
    });
    finalAnswers.push({
      value: 'Autogiro den 27:e varje månad',
      dataType: 'string',
      label: 'Betalningssätt',
    });
    console.log('finalAnswers', finalAnswers);
  };

  addValues();

  if (error) console.log(error);

  if (loading) return <S.H3>Loading...</S.H3>;

  return (
    data && (
      <S.LetterContainer>
        <Header logoSrc={logoArray}>
          <S.H1>
            Försäkringsbrev <br />
            lin Värdefacksförsäkring
          </S.H1>
          <S.H3>Försäkringsnummer: {data.policyNumber || '1234'}</S.H3>
          {/* TODO: remove default policyNumber above */}
        </Header>
        <main>
          <PolicyHolder
            firstName={data.answers.firstName}
            lastName={data.answers.lastName}
          />
          <PolicyContent answers={finalAnswers} nationality={SV_SE} />
          <S.DisclaimerText>
            Detta försäkringsbrev beskriver din försäkring. Det är viktigt att
            du kontrollerar att de uppgifter vi har om dig och din försäkrade
            förvaringsenhet är korrekta eftersom de ligger till grund för din
            premie och hur mycket du betalar för din försäkring.
          </S.DisclaimerText>
          <S.DisclaimerText>
            Felaktiga uppgifter kan påverka ersättningen vid skada.
          </S.DisclaimerText>
          <PolicyTable
            tableContents={tableValues}
            tableHeadings={tableHeadings}
          />
          <PaymentSchedule
            heading='Betalningsplan'
            paymentSchedule={data.paymentSchedule.schedule}
          />
        </main>
      </S.LetterContainer>
    )
  );
};

export default PolicyLetterView;
