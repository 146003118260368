import styled from 'styled-components';

import { useQuote } from '../../../context';

const NoQuote = () => {
  const { resetContext } = useQuote();

  const handleTryAgainClick = () => resetContext();

  return (
    <NoQuoteWrapper>
      <h3>Något gick fel</h3>
      <p>
        Tyvärr kan vi inte ge dig något pris. Kontrollera att alla uppgifter är
        korrekt ifyllda och försök igen. Om problemet kvarstår kontakta vår
        kundtjänst på telefon <Phone>08 - 520 276 58</Phone> eller
        info@linforsakring.se så hjälper vi dig.
      </p>
      <ButtonContainer>
        <Button type='button' onClick={handleTryAgainClick}>
          Försök igen
        </Button>
      </ButtonContainer>
    </NoQuoteWrapper>
  );
};

export default NoQuote;

const NoQuoteWrapper = styled.div`
  max-width: 40rem;
  margin-bottom: 2rem;
  border: 0.125rem solid #08a9bc;
  background-color: #d2edf1;
  padding: 0.5rem;
`;

const Phone = styled.span`
  white-space: nowrap;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const Button = styled.button`
  background-color: #08a9bc;
  font-family: Poppins;
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    opacity: 0.85;
  }
`;
