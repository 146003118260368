const Documents = () => {
  return (
    <>
      <h3>Viktig informaton</h3>
      <p>
        Läs om vår hantering av{' '}
        <a
          href='https://linforsakring.se/integritetspolicy/'
          rel='noreferrer'
          target='_blank'
        >
          personuppgifter
        </a>
        <br />
        <a
          href={`${process.env.PUBLIC_URL}/documents/Försäkringsvillkor SwedVault lin Försäkring 211203.pdf`}
          download
        >
          Försäkringsvillkor
        </a>
        <br />
        <a
          href={`${process.env.PUBLIC_URL}/documents/FKI SwedVault lin Försäkring 211203.pdf`}
          download
        >
          Förköpsinformation
        </a>
        <br />
        <a
          href={`${process.env.PUBLIC_URL}/documents/IPID SwedVault.pdf`}
          download
        >
          Produktblad
        </a>
        <br />
      </p>
    </>
  );
};

export default Documents;
