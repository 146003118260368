import styled from 'styled-components';
import DatePicker, { registerLocale } from 'react-datepicker';
import sv from 'date-fns/locale/sv';

import 'react-datepicker/dist/react-datepicker.css';
import '../../../styles/datepicker-fix.css';
import { useQuote } from '../../../context';

registerLocale('sv', sv);

const CustomerInformation = ({ data, errors, handleChange }) => {
  const { orderNumber, socialSecurity } = useQuote();

  const handleDateChange = date => {
    return date;
  };

  const handleDateChangeRaw = e => e.preventDefault();

  return (
    <FormWrapper>
      <InputRow>
        <InputContainer>
          <label>Personnummer</label>
          <input type='text' value={socialSecurity} disabled />
        </InputContainer>
        <InputContainer>
          <label>Värdefackets objektsnummer</label>
          <input type='text' value={orderNumber} disabled />
        </InputContainer>
      </InputRow>
      <InputRow>
        <InputContainer>
          <label>Förnamn</label>
          <input
            name='firstName'
            value={data.firstName}
            onChange={handleChange('firstName')}
            autoFocus
          />
          <ValidationErrorText>{errors?.firstName}</ValidationErrorText>
        </InputContainer>
        <InputContainer>
          <label>Efternamn</label>
          <input
            type='text'
            name='lastName'
            value={data.lastName}
            onChange={handleChange('lastName')}
          />
          <ValidationErrorText>{errors?.lastName}</ValidationErrorText>
        </InputContainer>
      </InputRow>
      <InputRow>
        <InputContainer>
          <label>Telefonnummer</label>
          <input
            type='text'
            name='phoneNumber'
            value={data.phoneNumber}
            onChange={handleChange('phoneNumber')}
          />
          <ValidationErrorText>{errors?.phoneNumber}</ValidationErrorText>
        </InputContainer>
        <InputContainer>
          <label>E-post</label>
          <input
            type='text'
            name='email'
            value={data.email}
            onChange={handleChange('email')}
          />
          <ValidationErrorText>{errors?.email}</ValidationErrorText>
        </InputContainer>
      </InputRow>
      <InputRow>
        <InputContainer>
          <label>Postnummer</label>
          <input
            type='text'
            name='postCode'
            value={data.postCode}
            onChange={handleChange('postCode')}
          />
          <ValidationErrorText>{errors?.postCode}</ValidationErrorText>
        </InputContainer>
        <InputContainer>
          <label>Startdatum för försäkring</label>
          <DatePicker
            locale='sv'
            dateFormat='yyyy-MM-dd'
            minDate={new Date()}
            selected={data.startDate || null}
            onChange={handleChange('startDate', handleDateChange)}
            onChangeRaw={handleDateChangeRaw}
          />
        </InputContainer>
      </InputRow>
      {/* <Button type='button' onClick={handleSubmit}>
        Se ditt pris
      </Button> */}
    </FormWrapper>
  );
};

export default CustomerInformation;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 43.75rem) {
    flex-direction: row;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 43.75rem) {
    width: 50%;
  }

  label {
  }
`;

const Button = styled.button`
  background-color: #08a9bc;
  font-family: Poppins;
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  border: none;
  padding: 1rem 1.5rem;
  cursor: pointer;

  &:hover {
    opacity: 0.85;
  }
`;

const ValidationErrorText = styled.span`
  font-size: 0.8125rem;
  color: #f2545b;
`;
