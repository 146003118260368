import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
* {
  outline: none;
  box-sizing: border-box;
  margin: 0;
}

html {
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  color: #444;
}

body {
  background-color: #fff;
}

h1, h2, h3 {
  font-family: Poppins;
  font-weight: 700;
}

h1 {
  font-size: 2.2rem;
  line-height: 1.3;

  @media (min-width: 40rem) {
    font-size: 3.125rem;
  }
}

h2 {
  font-size: 2rem;
}

h3 {
  font-weight: 500;
  font-size: 1.3rem;
}

a {
  color: #444;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] {
  -moz-appearance: textfield;
}

input {
  font-size: 1rem;
  font-weight: 400;
  padding: 0.625rem;
  border: 0.0625rem solid #d3d3cf;
  background-color: #fff;
  line-height: 1.5rem;
  transition: all 0.2s ease;

  &:focus {
    background-color: #ebebeb;
  }

  &:hover {
    border-color:#00aabc
  }

  &:disabled {
    font-weight: 700;
    border: none;
  }
}


`;

export default GlobalStyle;
